import React from "react";
import { useIntl } from "gatsby-plugin-react-intl";

const Partners = () => {
  const intl = useIntl();
  return (
    <div className="max-w-5xl mx-auto">
      <div className="flex flex-col items-center gap-4 mb-12 text-center">
        <h2 className="mb-2 font-medium leading-tight ">
          Sponsor Tytularny <br />
          Up To Date Festival 2024
        </h2>
        <a href="https://electrum.pl" target="_blank" rel="noreferrer nofollow">
          <img
            className="w-full max-w-[16rem] mx-auto"
            src="/partnerzy/electrum.svg"
            alt="Electrum"
          />
        </a>
      </div>

      <h3 className="mb-4 text-center">
        {intl.formatMessage({ id: "Patronaty" })}
      </h3>
      <div className="mb-8">
        <a
          href="https://www.bialystok.pl/"
          target="_blank"
          rel="noreferrer nofollow"
        >
          <img
            className="w-full max-w-[12rem] mx-auto"
            src="/partnerzy/2024/sponsorzy_Prezydent2.svg"
            alt="Białystok"
          />
        </a>
      </div>

      <h3 className="mb-4 text-center">{intl.formatMessage({ id: "Partners" })}</h3>
      <div className="grid items-center grid-cols-2 mb-8 gap-x-4 gap-y-8 sm:grid-cols-4">

      <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Bialstok2.svg"
          alt="Bialstok2"
        />


<img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Podlaskie2.svg"
          alt="Podlaskie2"
        />
        <img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_BTL.svg"
          alt="BTL"
        />

<img
          className="w-full max-w-[4rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Arsenal.svg"
          alt="Arsenal"
        />


<img
          className="w-full max-w-[4rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Nieteatr.svg"
          alt="Nieteatr"
        />




<img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_on_lemon.svg"
          alt="ON Lemon"
        />

<img
          className="w-full max-w-[8rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_RanyJulek.svg"
          alt="RanyJulek"
        />

<img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Pizzamajstry.svg"
          alt="Pizzamajstry"
        />


<img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Sodi.svg"
          alt="Soodi"
        />

<img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Sowa.svg"
          alt="Sowa"
        />

        
        <img
          className="w-full max-w-[8rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Biletomat.svg"
          alt="Biletomat"
        />


<img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Granko.svg"
          alt="Granko"
        />


<img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_NewHope.svg"
          alt="NewHope"
        />
        
        <img
                  className="w-full max-w-[6rem] mx-auto"
                  src="/partnerzy/2024/sponsorzy_casa_musica.svg"
                  alt="Casa Musica"
                />
                <img
                          className="w-full max-w-[4rem] mx-auto"
                          src="/partnerzy/2024/sponsorzy_wild_zone.svg"
                          alt="Wild Zone"
                        />


<img
                  className="w-full max-w-[6rem] mx-auto"
                  src="/partnerzy/2024/sponsorzy_Zachodnia.svg"
                  alt="Kawiarnia Zachodnia"
                />
                <img
                          className="w-full max-w-[6rem] mx-auto"
                          src="/partnerzy/2024/hilton.png"
                          alt="Hilton"
                        />




        </div>
        <div>
        <h3 className="mb-4 text-center">{intl.formatMessage({ id: "Media" })}</h3>
        <div className="grid items-center grid-cols-2 mb-8 gap-x-4 gap-y-8 sm:grid-cols-4">


<img
          className="w-full max-w-[8rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Akadera.svg"
          alt="Akadera"
        />

<img
          className="w-full max-w-[8rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_NowySwiat.svg"
          alt="NowySwiat"
        />

<img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Kampus.svg"
          alt="Kampus"
        />


<img
                                  className="w-full max-w-[6rem] mx-auto"
                                  src="/partnerzy/2024/Keyi.svg"
                                  alt="Keyi"
                                />

        <img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_NowaMuzyka.svg"
          alt="NowaMuzyka"
        />
        <img
          className="w-full max-w-[6rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Clot.svg"
          alt="Clot"
        />


<img
          className="w-full max-w-[4rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Punctum.svg"
          alt="Punctum"
        />
        <img
          className="w-full max-w-[5rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_Trojka.svg"
          alt="Trójka PR"
        />
        
        <img
          className="w-full max-w-[5rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_czworka.svg"
          alt="Czwórka"
        />
        
        <img
          className="w-full max-w-[5rem] mx-auto"
          src="/partnerzy/2024/sponsorzy_mint.svg"
          alt="mint"
        />
  </div>

      </div>
    </div>
  );
};

export default Partners;
